import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Room } from "@store/slices/roomsSlice";
import { ICruise, Market } from "@store/slices/searchSlice";
import { useTypedSelector } from "@store/store";
import { formatCurrency } from "@utils/helpers/currency";

import styles from "./index.module.scss";

const AVAILABLE_MARKETS: Array<keyof ICruise["markets"]> = [
  "inside",
  "outside",
  "balcony",
  "suite",
];

interface IStateroomMarketsProps {
  room?: Room;
  handleChoseMarket: (stateroom: Room) => void;
}

function StateroomsMarkets({ room, handleChoseMarket }: IStateroomMarketsProps) {
  const { t } = useTranslation();

  const { cruise } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { subtract_gft } = useTypedSelector((state) => state.environment);

  const availableRates: string[] = useMemo(() => {
    let output: string[] = [];

    if (room?.fare) {
      output = room.fare.prices.map((price) => price.grade);

      return output;
    }

    return output;
  }, [room?.fare]);

  const availableGrades: string[] = useMemo(() => {
    let output: string[] = [];

    if (cruise?.ship.grades) {
      output = cruise.ship.grades
        .filter((grade) => availableRates.includes(grade.code))
        .map((grade) => grade.meta_category);

      return output;
    }

    return output;
  }, [room?.fare]);

  const handleMarketClick = (marketType: keyof ICruise["markets"]) => () => {
    const updatedStateroom = structuredClone({
      ...room,
      marketType,
    });

    delete updatedStateroom.grade;

    const element = document.getElementById("pricing");

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    handleChoseMarket(updatedStateroom);
  };

  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <p className={styles.title}>{t("staterooms available")}</p>

        <div className={styles.markets}>
          {AVAILABLE_MARKETS.map((marketKey) => {
            const market = cruise?.markets[marketKey] as Market;
            const marketLabel = cruise?.ship.labels?.[marketKey] ?? marketKey;

            const price =
              market?.price && market?.price !== "0"
                ? formatCurrency(
                    parseInt(`${+market.price - +(subtract_gft ? market.gft : 0)}`),
                    rooms?.[1]?.pricing?.payment_schedule?.[0]?.currency ??
                      cruise?.markets.currency ??
                      "USD",
                  ).slice(0, -3)
                : "N/A";

            const isActive = marketKey === room?.marketType;
            const isDisabled = !availableGrades.includes(marketKey) || price === "N/A";

            return (
              <div
                key={marketKey}
                role="button"
                tabIndex={0}
                className={classNames(styles.market, {
                  [styles.market_active]: isActive,
                  [styles.market_disabled]: isDisabled,
                })}
                onClick={!isDisabled ? handleMarketClick(marketKey) : undefined}
                onKeyPress={(e) => {
                  if (!isDisabled && (e.key === "Enter" || e.key === " ")) {
                    handleMarketClick(marketKey)();
                  }
                }}
              >
                <p className={styles.marketName}>{marketLabel}</p>
                <p className={styles.from}>{t("From")}</p>

                <p className={styles.price}>
                  {price}
                  {!isDisabled ? "pp" : ""}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

StateroomsMarkets.defaultProps = {
  room: {},
};

export default StateroomsMarkets;
