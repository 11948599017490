import { useTranslation } from "react-i18next";

import LoadingContainer from "@componentsV1/containers/LoadingContainer";
import CustomSelect from "@componentsV1/shared/CustomSelect";
import { Room } from "@store/slices/roomsSlice";
import { Deck } from "@store/slices/searchSlice";
import { useTypedSelector } from "@store/store";

import styles from "./index.module.scss";

interface IDeckPlansProps {
  room?: Room;
  decks?: Array<{ value: string; label: string }>;
  handleChoseDeck: (room: Room) => void;
}

function DeckPlans({ room, decks, handleChoseDeck }: IDeckPlansProps) {
  const { t } = useTranslation();

  const { cruise } = useTypedSelector((state) => state.search);
  const { isCabinsLoading, cabins } = useTypedSelector((state) => state.rooms);
  const { deck_legend } = useTypedSelector((state) => state.environment);

  const handleDeckChange = ({ value }: { value: string }) => {
    const deck: Deck | undefined = cruise?.ship.decks.find((el) => el.code === value);

    const updatedStateroom = structuredClone({ ...room, deck });

    handleChoseDeck(updatedStateroom);
  };

  return (
    <LoadingContainer isLoading={isCabinsLoading}>
      <div className={styles.container}>
        <p className={styles.title}>{t("deck plans")}</p>

        {cabins?.length ? (
          <div className={styles.selectContainer}>
            <CustomSelect
              className={styles.select}
              label={t("choose a deck")}
              placeholder={t("select a deck")}
              value={room?.deck?.code ?? ""}
              items={decks ?? []}
              onChange={handleDeckChange}
            />

            {room?.deck?.images[0] && <img src={room?.deck?.images[0] ?? ""} alt={t("deck")} />}

            {deck_legend && (
              <img className={styles.deckImage} src={deck_legend} alt="deck legend" />
            )}
          </div>
        ) : (
          <p className={styles.dataInfo}>{t("No data to display")}</p>
        )}
      </div>
    </LoadingContainer>
  );
}

DeckPlans.defaultProps = {
  room: {},
  decks: [],
};

export default DeckPlans;
