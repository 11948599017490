import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export interface IQueryParams {
  oagency: string | null;
  oagent: string | null;
}

export const useQueryParams = (): IQueryParams => {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);

    return {
      oagency: params.get("oagency"),
      oagent: params.get("oagent"),
    };
  }, [search]);
};
