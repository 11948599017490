import { Button, Typography, Grid2 as Grid } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ICruise } from "@store/slices/searchSlice";
import { useTypedSelector } from "@store/store";
import { formatCurrency } from "@utils/helpers/currency";

const AVAILABLE_MARKETS: Array<keyof ICruise["markets"]> = [
  "inside",
  "outside",
  "balcony",
  "suite",
];

interface ICabinPricingOptionsProps {
  selectedMarketType?: string;
}

export function CabinPricingOptions({ selectedMarketType }: ICabinPricingOptionsProps) {
  const { t } = useTranslation();
  const { cruise } = useTypedSelector((state) => state.search);
  const { subtract_gft } = useTypedSelector((state) => state.environment);

  const currencyCode = cruise?.pricing?.[0]?.currency ?? "USD";

  const pricingOptions = useMemo(() => {
    return AVAILABLE_MARKETS.map((marketKey) => {
      const market = cruise?.markets[marketKey];

      const price =
        typeof market === "object" && market?.price && market.price !== "0"
          ? formatCurrency(
              parseInt(`${+market.price - +(subtract_gft ? market.gft : 0)}`, 10),
              currencyCode,
            )
          : "N/A";

      return {
        marketKey,
        price,
      };
    }).filter(({ price }) => price !== "N/A");
  }, [cruise?.markets, subtract_gft, selectedMarketType]);

  return (
    <Grid container spacing={2} mt={{ xs: 4, md: 8 }}>
      {pricingOptions.map(({ marketKey, price }) => (
        <Grid
          key={marketKey}
          size={{ xs: 12, md: 3 }}
          sx={{
            py: 1,
            px: 2,
            border: "3px solid",
            borderColor: "bg-button.main",
            borderRadius: (theme) => theme.toPx(theme.customShape.largeRadius),
          }}
        >
          <Typography
            variant="smallHeader"
            color="text.dark"
            textTransform="capitalize"
            component="p"
          >
            {`${t(marketKey)} Twin Share`}
          </Typography>

          <Typography
            component="span"
            variant="footnote"
            color="text-primary"
            textTransform="uppercase"
          >
            {t("From")}

            <Typography component="span" variant="paragraphTitle">
              {` ${price} `}
            </Typography>

            <Typography component="p" variant="footnote">
              {t("PER PERSON TWIN SHARE")}
            </Typography>
          </Typography>
        </Grid>
      ))}

      <Grid size={{ xs: 12, md: 3 }}>
        <Button
          variant="containedPrimary"
          size="medium"
          sx={{ mt: { xs: 2, md: 0 }, height: "100%" }}
          onClick={() => null}
          fullWidth
        >
          {t("Enquire Now")}
        </Button>
      </Grid>
    </Grid>
  );
}
