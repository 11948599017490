import { Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";

export function CruiseTags() {
  const { cruise } = useTypedSelector((state) => state.search);

  const CTYTags = useMemo(() => {
    const CTYTags = cruise?.cruise?.tags?.filter((tag) => tag.type === "CTY");

    return CTYTags;
  }, [cruise?.cruise?.tags]);

  return (
    <Box
      sx={{
        pt: 2,
      }}
    >
      <Stack>
        <Typography component="p" variant="smallHeader" color="text-primary">
          {CTYTags?.map((tag) => tag.name).join(", ")}
        </Typography>
      </Stack>
    </Box>
  );
}
