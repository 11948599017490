import classNames from "classnames";

import { ReactComponent as Checked } from "@assets/icons/checked.svg";
import { ReactComponent as Info } from "@assets/icons/info.svg";
import { ReactComponent as Logo } from "@assets/icons/logo.svg";
import { ReactComponent as Loupe } from "@assets/icons/loupe.svg";
import { ReactComponent as Minus } from "@assets/icons/minus.svg";
import { ReactComponent as Plus } from "@assets/icons/plus.svg";
import { ReactComponent as Unchecked } from "@assets/icons/unchecked.svg";

import styles from "./index.module.scss";

const BASE: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  logo: Logo,
  plus: Plus,
  minus: Minus,
  checked: Checked,
  unchecked: Unchecked,
  loupe: Loupe,
  info: Info,
};

const SVG_BY_TYPE = {
  ...BASE,
};

interface ISvgIconProps {
  type: keyof typeof SVG_BY_TYPE;
  className?: string;
  onClick?: () => void;
}

function SvgIcon({ type, className, onClick }: ISvgIconProps) {
  const CustomSvg = SVG_BY_TYPE[type] || "svg";

  return <CustomSvg className={classNames(styles.icon, className)} onClick={onClick} />;
}

SvgIcon.defaultProps = {
  className: "",
  onClick: () => null,
};

export default SvgIcon;
