import { Theme } from "@mui/material";

export const crtPalette = (theme: Theme) => ({
  "bg-primary": theme.palette.augmentColor({
    name: "bg-primary",
    color: {
      main: "#FFFFFF",
    },
  }),
  "bg-secondary": theme.palette.augmentColor({
    name: "bg-secondary",
    color: {
      main: "#3C82BB",
    },
  }),
  "bg-dark": theme.palette.augmentColor({
    name: "bg-dark",
    color: {
      main: "#303030",
    },
  }),
  "bg-button": theme.palette.augmentColor({
    name: "bg-button",
    color: {
      main: "#0A80BC",
    },
  }),
  "text-primary": theme.palette.augmentColor({
    name: "text-primary",
    color: {
      main: "#3C82BB",
    },
  }),
  "text-light": theme.palette.augmentColor({
    name: "text-light",
    color: {
      main: "#FFFFFF",
    },
  }),
  "text-dark": theme.palette.augmentColor({
    name: "text-dark",
    color: {
      main: "#303030",
    },
  }),
  error: theme.palette.augmentColor({
    name: "error",
    color: {
      main: "#FB4649",
    },
  }),
  "border-color": theme.palette.augmentColor({
    name: "border-color",
    color: {
      main: "#1B4E65",
    },
  }),
});
