import { store } from "@store/store";

export const currencyToFormat = (value: number | string, currency: string) => {
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
  }).format(Number(value));

  return formatted;
};

export const currencyWithCode = (value: number | string, currency = "USD") => {
  return `${Number(value).toLocaleString("en-US", {
    minimumFractionDigits: 0,
  })}${currency}`;
};

export const formatCurrency = (value: number | string, currencyCode = "USD") => {
  const state = store.getState();
  const formatMode = state.environment.currency_format_mode || "intl";

  return formatMode === "intl"
    ? currencyToFormat(value, currencyCode)
    : currencyWithCode(value, currencyCode);
};
