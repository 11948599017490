import classNames from "classnames";
import { Link } from "react-router-dom";

import LoadingIndicator from "@componentsV1/shared/LoadingIndicator";
import SvgIcon from "@componentsV1/shared/SvgIcon";

import styles from "./index.module.scss";

interface IButtonProps {
  id?: string;
  label: string;
  type?: "button" | "submit" | "reset";
  icon?: string;
  variant?: "primary" | "secondary" | "dark";
  onClick?: () => void | Promise<void>;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  url?: string;
}

function Button({
  id,
  label,
  type = "button",
  icon,
  variant,
  onClick,
  loading,
  disabled,
  className,
  url,
}: IButtonProps) {
  const Component = url ? Link : "button";

  return (
    <Component
      id={id}
      to={url ?? ""}
      className={classNames(styles.button, styles[`button_${variant ?? "primary"}`], className, {
        [styles.button_withIcon]: icon,
      })}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading ? <LoadingIndicator width={30} height={30} /> : label}

      {icon && <SvgIcon type={icon} />}
    </Component>
  );
}

Button.defaultProps = {
  id: "",
  type: "button",
  icon: "",
  variant: "primary",
  onClick: () => null,
  loading: false,
  disabled: false,
  className: "",
};

export default Button;
