import ExpandMoreIcon from "@mui/icons-material/Add";
import ExpandLessIcon from "@mui/icons-material/Remove";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Grid2 as Grid,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";

import { useTypedSelector } from "@store/store";

export function DeckPlansAccordion() {
  const { cruise } = useTypedSelector((state) => state.search);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState<string | null>(null);
  const [dialogTitle, setDialogTitle] = useState<string | null>(null);

  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleImageClick = (image: string, title: string) => {
    setDialogImage(image);
    setDialogTitle(title);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setDialogImage(null);
    setDialogTitle(null);
  };

  return (
    <>
      <Grid container size={12} spacing={{ xs: 2, md: 4 }} sx={{ width: "100%", mt: 4 }}>
        {(cruise?.ship.decks ?? []).map((deck, index) => {
          return (
            <Grid key={deck.name} size={{ xs: 12, md: 6 }}>
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{ mb: 2 }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === `panel${index}` ? (
                      <ExpandLessIcon color="text-primary" />
                    ) : (
                      <ExpandMoreIcon color="text-primary" />
                    )
                  }
                  sx={{ height: 94, px: 4, pb: 0, display: "flex", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pr: 2,
                      flexGrow: 1,
                    }}
                  >
                    <Typography component="p" variant="smallHeader" color="text-dark">
                      {`${cruise?.ship.name} ${deck.name}`}
                    </Typography>
                  </Box>
                </AccordionSummary>

                <AccordionDetails sx={{ p: 4, pt: 0 }}>
                  <Grid container spacing={{ xs: 2, md: 4 }}>
                    {deck.images.map((image) => {
                      return (
                        <Grid
                          key={image}
                          component="img"
                          size={12}
                          src={image}
                          onClick={() =>
                            handleImageClick(image, `${cruise?.ship.name} ${deck.name}`)
                          }
                          sx={{
                            width: "100%",
                            maxWidth: { xs: "auto", sm: 160 },
                            height: "auto",
                            objectFit: "contain",
                            cursor: "pointer",
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>

      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="xs" fullWidth>
        {dialogTitle && (
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pr: 2,
              py: 1,
            }}
          >
            <Typography variant="subtitle1" component="span">
              {dialogTitle}
            </Typography>

            <Box component="span" onClick={handleClose} sx={{ cursor: "pointer", fontSize: 24 }}>
              ×
            </Box>
          </DialogTitle>
        )}

        <DialogContent sx={{ p: 0 }}>
          <Box
            component="img"
            src={dialogImage ?? ""}
            alt="Deck Plan"
            sx={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
              display: "block",
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
