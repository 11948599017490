import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import dayjs from "dayjs";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import CustomCarousel from "@componentsV1/shared/Carousel";
import Collapsible from "@componentsV1/shared/Collapsible";
import { Market } from "@store/slices/searchSlice";
import { useTypedSelector } from "@store/store";
import { formatCurrency } from "@utils/helpers/currency";

import styles from "./index.module.scss";

function ItineraryModal() {
  const { t } = useTranslation();

  const { cruise } = useTypedSelector((state) => state.search);

  const { strip_no_stop_itinerary_items, date_format } = useTypedSelector(
    (state) => state.environment,
  );

  const price = useMemo(() => {
    if (!cruise) {
      return 0;
    }

    const prices = Object.values(cruise.markets)
      .filter(
        (market): market is Market =>
          typeof market === "object" && market !== null && "price" in market,
      )
      .map((market) => parseFloat(market.price))
      .filter((marketPrice) => !Number.isNaN(marketPrice) && marketPrice > 0);

    return Math.min(...prices);
  }, []);

  const validateTime = (value: string | null) => {
    if (value === "00:00" && strip_no_stop_itinerary_items) {
      return false;
    }

    return true;
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.imagesContainer}>
          <div
            className={classNames(styles.images, {
              [styles.images_twoColumns]: cruise?.cruise.map,
            })}
          >
            <CustomCarousel items={cruise?.ship.images ?? []} />

            {cruise?.cruise.map && (
              <img src={cruise?.cruise.map} className={styles.map} alt="cruise map" />
            )}
          </div>

          <p className={styles.name}>{cruise?.cruise.name ?? ""}</p>
        </div>

        <table className={styles.details}>
          <tbody>
            <tr>
              <td>{t("SHIP NAME")}</td>
              <td>{cruise?.ship.name ?? ""}</td>
            </tr>

            <tr>
              <td>{t("DEPARTS")}</td>
              <td>{dayjs(cruise?.embark ?? "").format(date_format)}</td>
            </tr>

            <tr>
              <td>{t("NIGHTS")}</td>
              <td>{cruise?.cruise.nights}</td>
            </tr>
          </tbody>
        </table>

        <div className={styles.price}>
          <span className={styles.price_from}>From</span>

          <span className={styles.price_value}>
            {formatCurrency(price, cruise?.pricing?.[0]?.currency ?? "USD")}
          </span>
        </div>

        <div className={styles.itinerary}>
          {cruise?.cruise.itinerary.map((it) => {
            const { port, arrive_time, depart_time, description } = it;

            const isTimeValid = validateTime(arrive_time) && validateTime(depart_time);

            if (!isTimeValid) {
              return <Fragment key={nanoid()} />;
            }

            return (
              <Collapsible
                key={nanoid()}
                className={styles.svg_ico}
                renderHeader={() => <p className={styles.itineraryTitle}>{port}</p>}
              >
                <table className={styles.details}>
                  <tbody>
                    {arrive_time && (
                      <tr>
                        <td>ARRIVES</td>
                        <td>{arrive_time}</td>
                      </tr>
                    )}

                    {depart_time && (
                      <tr>
                        <td>DEPARTS</td>
                        <td>{depart_time}</td>
                      </tr>
                    )}

                    {description && (
                      <tr className={styles.description}>
                        <td>DESCRIPTION</td>
                        <td dangerouslySetInnerHTML={{ __html: description }} />
                      </tr>
                    )}
                  </tbody>
                </table>
              </Collapsible>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ItineraryModal;
