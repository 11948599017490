"use client";

import { createTheme, PaletteColor } from "@mui/material/styles";

import { lato } from "@fonts/latoFont";
import { MuiAccordion } from "@theme/components/Accordion";
import { MuiAppBar } from "@theme/components/AppBar";
import { MuiButton } from "@theme/components/Button";
import { MuiChip } from "@theme/components/Chip";
import { MuiLink } from "@theme/components/Link";
import { MuiTab } from "@theme/components/Tab";
import { MuiTabs } from "@theme/components/Tabs";
import { MuiToolbar } from "@theme/components/Toolbar";
import { UIVersion } from "@utils/constants/routes";

import { crtPalette } from "./palettes";

declare module "@mui/material/styles" {
  interface Palette {
    "bg-primary": Palette["primary"];
    "border-color": Palette["primary"];
    "bg-secondary": Palette["primary"];
    "bg-dark": Palette["primary"];
    "bg-button": Palette["primary"];
    "text-primary": Palette["primary"];
    "text-light": Palette["primary"];
    "text-dark": Palette["primary"];
  }

  interface PaletteOptions {
    "bg-primary": PaletteOptions["primary"];
    "border-color": PaletteOptions["primary"];
    "bg-secondary": PaletteOptions["primary"];
    "bg-dark": PaletteOptions["primary"];
    "bg-button": PaletteOptions["primary"];
    "text-primary": PaletteOptions["primary"];
    "text-light": PaletteOptions["primary"];
    "text-dark": PaletteOptions["primary"];
  }

  interface Theme {
    toPx: (value: number) => string;
    customShape: {
      smallRadius: number;
      largeRadius: number;
    };
  }

  interface ThemeOptions {
    toPx: (value: number) => string;
    customShape?: {
      smallRadius?: number;
      largeRadius?: number;
    };
  }

  interface TypographyVariants {
    smallHeader: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    smallHeader?: React.CSSProperties;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    "bg-primary": true;
    "border-color": true;
    "bg-secondary": true;
    "bg-dark": true;
    "bg-button": true;
    "text-primary": true;
    "text-light": true;
    "text-dark": true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    "bg-primary": true;
    "border-color": true;
    "bg-secondary": true;
    "bg-dark": true;
    "bg-button": true;
    "text-primary": true;
    "text-light": true;
    "text-dark": true;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    "bg-primary": true;
    "border-color": true;
    "bg-secondary": true;
    "bg-dark": true;
    "bg-button": true;
    "text-primary": true;
    "text-light": true;
    "text-dark": true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsColorOverrides {
    "bg-primary": true;
    "border-color": true;
    "bg-secondary": true;
    "bg-dark": true;
    "bg-button": true;
    "text-primary": true;
    "text-light": true;
    "text-dark": true;
  }

  interface TypographyPropsVariantOverrides {
    heroTitle: true;
    sectionTitle: true;
    subtitle: true;
    sectionHeader: true;
    tab: true;
    paragraphTitle: true;
    smallHeader: true;
    footnote: true;
  }
}

const baseTheme = createTheme({
  customShape: {
    smallRadius: 10,
    largeRadius: 20,
  },
  toPx: (value: number) => `${value}px`,
});

const UI_VERSION = process.env.REACT_APP_UI_VERSION as UIVersion | undefined;

const PALETTES_BY_TYPE: Record<UIVersion, Record<string, PaletteColor>> = {
  v1: crtPalette(baseTheme),
  crt: crtPalette(baseTheme),
  awx: crtPalette(baseTheme),
};

const palette = PALETTES_BY_TYPE[UI_VERSION || "v1"];

const theme = createTheme(baseTheme, {
  typography: {
    heroTitle: {
      fontFamily: lato.fontFamily,
      fontWeight: 700,
      fontSize: baseTheme.typography.pxToRem(26),
      lineHeight: baseTheme.typography.pxToRem(48),
      "@media (min-width: 376px) and (max-width: 1024px)": {
        fontSize: baseTheme.typography.pxToRem(50),
        lineHeight: baseTheme.typography.pxToRem(76),
      },
      "@media (min-width: 1025px)": {
        fontSize: baseTheme.typography.pxToRem(60),
        lineHeight: baseTheme.typography.pxToRem(90),
      },
    },
    sectionTitle: {
      fontFamily: lato.fontFamily,
      fontWeight: 700,
      fontSize: baseTheme.typography.pxToRem(22),
      lineHeight: baseTheme.typography.pxToRem(40),
      "@media (min-width: 376px) and (max-width: 1024px)": {
        fontSize: baseTheme.typography.pxToRem(40),
        lineHeight: baseTheme.typography.pxToRem(60),
      },
      "@media (min-width: 1025px)": {
        fontSize: baseTheme.typography.pxToRem(48),
        lineHeight: baseTheme.typography.pxToRem(68),
      },
    },
    subtitle: {
      fontFamily: lato.fontFamily,
      fontWeight: 700,
      fontSize: baseTheme.typography.pxToRem(20),
      lineHeight: baseTheme.typography.pxToRem(30),
      "@media (min-width: 376px) and (max-width: 1024px)": {
        fontSize: baseTheme.typography.pxToRem(34),
        lineHeight: baseTheme.typography.pxToRem(50),
      },
      "@media (min-width: 1025px)": {
        fontSize: baseTheme.typography.pxToRem(40),
        lineHeight: baseTheme.typography.pxToRem(60),
      },
    },
    sectionHeader: {
      fontFamily: lato.fontFamily,
      fontWeight: 700,
      fontSize: baseTheme.typography.pxToRem(18),
      lineHeight: baseTheme.typography.pxToRem(30),
      "@media (min-width: 376px) and (max-width: 1024px)": {
        fontSize: baseTheme.typography.pxToRem(24),
        lineHeight: baseTheme.typography.pxToRem(40),
      },
      "@media (min-width: 1025px)": {
        fontSize: baseTheme.typography.pxToRem(32),
        lineHeight: baseTheme.typography.pxToRem(50),
      },
    },
    tab: {
      fontFamily: lato.fontFamily,
      fontWeight: baseTheme.typography.fontWeightMedium,
      fontSize: baseTheme.typography.pxToRem(10),
      lineHeight: baseTheme.typography.pxToRem(18),
      "@media (min-width: 376px) and (max-width: 1024px)": {
        fontSize: baseTheme.typography.pxToRem(16),
        fontWeight: baseTheme.typography.fontWeightBold,
        lineHeight: baseTheme.typography.pxToRem(24),
      },
      "@media (min-width: 1025px)": {
        fontSize: baseTheme.typography.pxToRem(20),
        fontWeight: baseTheme.typography.fontWeightBold,
        lineHeight: baseTheme.typography.pxToRem(28),
      },
    },
    paragraphTitle: {
      fontFamily: lato.fontFamily,
      fontWeight: 700,
      fontSize: baseTheme.typography.pxToRem(16),
      lineHeight: baseTheme.typography.pxToRem(26),
      "@media (min-width: 376px) and (max-width: 1024px)": {
        fontSize: baseTheme.typography.pxToRem(20),
        lineHeight: baseTheme.typography.pxToRem(36),
      },
      "@media (min-width: 1025px)": {
        fontSize: baseTheme.typography.pxToRem(24),
        lineHeight: baseTheme.typography.pxToRem(40),
      },
    },
    smallHeader: {
      fontFamily: lato.fontFamily,
      fontWeight: 700,
      fontSize: baseTheme.typography.pxToRem(14),
      lineHeight: baseTheme.typography.pxToRem(22),
      "@media (min-width: 376px) and (max-width: 1024px)": {
        fontSize: baseTheme.typography.pxToRem(16),
        lineHeight: baseTheme.typography.pxToRem(30),
      },
      "@media (min-width: 1025px)": {
        fontSize: baseTheme.typography.pxToRem(18),
        lineHeight: baseTheme.typography.pxToRem(34),
      },
    },
    footnote: {
      fontFamily: lato.fontFamily,
      fontWeight: 500,
      fontSize: baseTheme.typography.pxToRem(12),
      lineHeight: baseTheme.typography.pxToRem(24),
      "@media (min-width: 376px) and (max-width: 1024px)": {
        fontSize: baseTheme.typography.pxToRem(14),
        lineHeight: baseTheme.typography.pxToRem(28),
      },
      "@media (min-width: 1025px)": {
        fontSize: baseTheme.typography.pxToRem(16),
        lineHeight: baseTheme.typography.pxToRem(30),
      },
    },
  },
  customShape: {
    smallRadius: 10,
    largeRadius: 20,
  },
  toPx: (value: number) => `${value}px`,
  components: { MuiAppBar, MuiToolbar, MuiButton, MuiLink, MuiTabs, MuiTab, MuiChip, MuiAccordion },
  palette,
});

export default theme;
