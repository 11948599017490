import { ComponentsOverrides, Theme } from "@mui/material";

interface IMuiChip {
  styleOverrides: ComponentsOverrides<Theme>["MuiChip"];
}

export const MuiChip: IMuiChip = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.smallHeader,
      fontWeight: 400,
      borderRadius: 10,
      padding: theme.spacing(3),
    }),
  },
};
