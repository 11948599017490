import { agencyApi } from "@store/services/apiSingleton";

export type IInitConsortiaResponse = Array<{
  code: string;
  commission: null;
  id: number;
  name: string;
}>;

export type IInitMarketsResponse = Array<{
  code: string;
  id: number;
  name: string;
}>;

export interface ICreateAgencyRequest {
  sales_area: string;
  api_accounts: string;
  name: string;
  commission_level: string;
  credit_limit: string;
  code: string;
  dba: string;
  address: { name: string; value: string };
  telephone: string;
  website: string;
  iata: string;
  clia: string;
  account_type: string;
  consortia: string;
  market: string;
  file?: File;
  title: string;
  firstName: string;
  surname: string;
  personalPhoneNumber: string;
  position: string;
  personalAddress: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface ICreateAgentRequest {
  sales_area: string;
  name: string;
  firstName: string;
  surname: string;
  address: { name: string; value: string };
  telephone: string;
  code: string;
  agency: string;
  position: string;
  iata: string;
  clia: string;
  email: string;
  password: string;
  confirmPassword: string;
}

type Address = {
  name: string;
  type: string;
  value: string;
};

type ApiAccount = {
  username: string;
  name: string;
};

type ISearchAgencyResponse = {
  id: number;
  code: string;
  uuid: string;
  market: string;
  consortia: string;
  name: string;
  address: Address[];
  telephone: string;
  email: string;
  account_type: "both" | "single" | "multi";
  credit_limit: string;
  commission_level: string;
  api_accounts: ApiAccount[];
  published: string;
  published_by: string;
  created: string;
  created_by: string;
  updated: string;
  updated_by: string;
  deleted: string | null;
  deleted_by: string | null;
  publish_attempt_status: "failed" | "success" | "pending";
  publish_attempt_processed: string | null;
  external_code: string;
  pos_sources: unknown | null;
  channels: unknown | null;
  regulatory: unknown | null;
  sales_area: unknown | null;
  send_customer_emails: boolean;
};

export const AgencyAPI = agencyApi.injectEndpoints({
  endpoints: (builder) => ({
    initConsortia: builder.query<IInitConsortiaResponse, null>({
      query: () => ({
        url: "/cnf/consortia/",
        method: "GET",
      }),
    }),
    initMarkets: builder.query<IInitMarketsResponse, null>({
      query: () => ({
        url: "/cnf/market/",
        method: "GET",
      }),
    }),
    createAgency: builder.query<null, ICreateAgencyRequest>({
      query: (body) => ({
        url: "/ops/agency/",
        method: "POST",
        body,
      }),
    }),
    createAgent: builder.query<null, ICreateAgentRequest>({
      query: (body) => ({
        url: "/ops/agent/",
        method: "POST",
        body,
      }),
    }),
    searchAgency: builder.query<ISearchAgencyResponse, string>({
      query: (id: string) => ({
        url: `/ops/agency/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazySearchAgencyQuery } = AgencyAPI;
