import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import LoadingContainer from "@componentsV1/containers/LoadingContainer";
import { useLazyRetrieveReservationQuery } from "@store/services/ReservationService";
import { Room } from "@store/slices/roomsSlice";
import { showToast } from "@store/slices/toastSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";
import TOAST from "@utils/constants/toast";
import { formatCurrency } from "@utils/helpers/currency";

import styles from "./index.module.scss";

function PaymentConfirmation() {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const [retrieveReservation] = useLazyRetrieveReservationQuery();

  const { rooms } = useTypedSelector((state) => state.rooms);
  const { cruise } = useTypedSelector((state) => state.search);
  const { reservation } = useTypedSelector((state) => state.reservation);

  const {
    api_agency: channel_partner,
    api_agent: agency,
    date_format,
  } = useTypedSelector((state) => state.environment);

  const [isLoading, setIsLoading] = useState(true);

  const grades = useMemo(() => {
    return Object.values(rooms ?? {}).map((room: Room) => room.grade);
  }, [rooms]);

  const sailing = useMemo(() => reservation?.sailings?.[0], [reservation]);

  useEffect(() => {
    const initReservation = async () => {
      try {
        const { isError } = await retrieveReservation({
          pnr: rooms?.[1]?.pnr ?? "",
          channel_partner,
          agency,
        });

        if (isError) {
          dispatch(
            showToast({
              type: TOAST.ERROR_TYPE,
              message: t("Retrieving the reservation failed"),
              duration: TOAST.DEFAULT_DURATION,
            }),
          );
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : String(error);

        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            message: t("Retrieving the reservation failed"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );

        throw new Error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    initReservation();

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.topSection}>
            <img
              src={cruise?.ship?.images?.[0] ?? ""}
              className={styles.mainImage}
              alt="cruise ship"
            />

            <div className={styles.reservationInfo}>
              <div className={styles.reservationItem}>
                <p className={styles.pnr}>{reservation?.pnr ?? ""}</p>

                <p className={styles.cruiseName}>{reservation?.sailings?.[0]?.cruise_name ?? ""}</p>

                <p className={styles.ship}>
                  {reservation?.sailings?.[0]?.ship_name ?? ""}{" "}
                  {`(${reservation?.sailings?.[0]?.ship_code ?? ""})`}
                </p>

                <p className={styles.embarking}>
                  {`${t("Embarking")} `}
                  {dayjs(reservation?.sailings?.[0]?.embarkation_date ?? "").format(
                    date_format,
                  )}{" "}
                  {reservation?.sailings?.[0]?.cruise_duration ?? ""}
                  {` ${t("nights")} `}({reservation?.sailings?.[0]?.sailing_code ?? ""})
                </p>
              </div>

              <div className={styles.reservationItem}>
                <p className={styles.guestInfo_primary}>
                  {reservation?.lead_title ?? ""} {reservation?.lead_given_name ?? ""}{" "}
                  {reservation?.lead_lastname ?? ""}
                </p>

                <p className={styles.guestInfo_secondary}>{reservation?.lead_email ?? ""}</p>

                <p className={styles.guestInfo_secondary}>{reservation?.lead_phone ?? ""}</p>
              </div>

              <div className={styles.reservationItem}>
                <p className={styles.priceInfo_price}>
                  {formatCurrency(
                    Number(reservation?.total_price ?? "0"),
                    cruise?.pricing?.[0]?.currency ?? "USD",
                  )}
                </p>

                <p className={styles.priceInfo_description}>
                  {reservation?.sailings?.[0]?.rate_code ?? ""}
                </p>
              </div>
            </div>
          </div>

          <div className={styles.tableContainer}>
            <h2 className={styles.tableContainer_title}>{t("passengers")}</h2>

            <div className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th>{t("name")}</th>
                    <th>{t("date of birth")}</th>
                    <th>{t("language")}</th>
                    <th>{t("price")}</th>
                  </tr>
                </thead>

                <tbody>
                  {(reservation?.sailings?.[0]?.cabins ?? []).map((cabin) => {
                    return (cabin?.guests ?? []).map((guest) => {
                      const {
                        uuid,
                        title,
                        given_name,
                        lastname,
                        date_of_birth,
                        nationality,
                        pricing,
                      } = guest;

                      return (
                        <tr key={uuid}>
                          <td>
                            <p>{`${title} ${given_name} ${lastname}`}</p>
                          </td>

                          <td>
                            <p>{dayjs(date_of_birth).format(date_format)}</p>
                          </td>

                          <td>
                            <p>{`${nationality ?? t("Not specified")}`}</p>
                          </td>

                          <td>
                            <p className={styles.textContent}>
                              <span>{`${t("Fare")}: `}</span>
                              {`${pricing.fare}`}
                            </p>

                            <p className={styles.textContent}>
                              <span>{`${t("Taxes")}: `}</span>
                              {`${pricing.gft}`}
                            </p>

                            <p className={styles.textContent}>
                              <span>{`${t("Extras")}: `}</span>
                              {`${pricing.extras}`}
                            </p>
                          </td>
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className={styles.tableContainer}>
            <h2 className={styles.tableContainer_title}>{t("cabins")}</h2>

            <div className={styles.info}>
              {(reservation?.sailings?.[0]?.cabins ?? []).map((cabin) => {
                const { guests, deck_name, cabin_number, grade_code } = cabin;
                const grade = grades?.find((el) => el?.code === grade_code);

                return (
                  <div key={cabin.uuid} className={styles.cabin}>
                    <div className={styles.guests}>
                      {(guests ?? []).map((guest) => {
                        const { uuid, title, given_name, lastname } = guest;

                        return (
                          <div key={uuid} className={styles.guest}>
                            <p>{`${title} ${given_name} ${lastname}`}</p>
                          </div>
                        );
                      })}
                    </div>

                    <div className={styles.deckInfo}>
                      <p>
                        <span>{`${t("Deck")}: `}</span>

                        {deck_name.split(" ")[1]}
                      </p>

                      <p>
                        <span>{`${t("Stateroom")}: `}</span>
                        {cabin_number}
                      </p>
                    </div>

                    <p className={styles.gradeName}>{grade?.name ?? ""}</p>

                    <p className={styles.gradeDescription}>
                      {grade?.descriptions?.[0]?.description ?? ""}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.tableContainer}>
            <div>
              <h2 className={styles.tableContainer_title}>{t("itinerary")}</h2>

              <p className={styles.tableContainer_subtitle}>{sailing.cruise_name}</p>
            </div>

            <div className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th>{t("date")}</th>
                    <th>{t("port")}</th>
                    <th>{t("arrive")}</th>
                    <th>{t("depart")}</th>
                    <th>{t("tender")}</th>
                  </tr>
                </thead>

                <tbody>
                  {sailing.itinerary.map((item) => {
                    const { date, port_name, port_code, arrive_time, depart_time, tender } = item;

                    return (
                      <tr key={nanoid()}>
                        <td>
                          <p>{dayjs(date).format(date_format)}</p>
                        </td>

                        <td>
                          <p>{`${port_name} (${port_code})`}</p>
                        </td>

                        <td>
                          <p>{`${arrive_time ?? "-"}`}</p>
                        </td>

                        <td>
                          <p>{`${depart_time ?? "-"}`}</p>
                        </td>

                        <td>
                          <p>{t(tender ? "Yes" : "No")}</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className={styles.paymentContainer}>
            <div className={styles.tableContainer}>
              <div>
                <h2 className={styles.tableContainer_title}>{t("payment schedule")}</h2>

                <p className={styles.tableContainer_subtitle}>
                  {`${t("Next payment of")} ${formatCurrency(
                    Number(reservation?.next_payment_outstanding ?? "0"),
                    cruise?.pricing?.[0]?.currency ?? "USD",
                  )} ${t("on")} ${dayjs(reservation?.next_payment_due ?? null).format(date_format)}`}
                </p>
              </div>

              <div className={styles.tableWrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>{t("payment date")}</th>
                      <th>{t("payment amount")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(reservation?.payment_schedule ?? []).map((item) => {
                      const { due_date, amount } = item;

                      return (
                        <tr key={nanoid()}>
                          <td>
                            <p>{dayjs(due_date).format(date_format)}</p>
                          </td>

                          <td>
                            <p>
                              {formatCurrency(
                                Number(amount),
                                cruise?.pricing?.[0]?.currency ?? "USD",
                              )}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className={styles.tableContainer}>
              <div>
                <h2 className={styles.tableContainer_title}>{t("transactions")}</h2>

                <p className={styles.tableContainer_subtitle}>
                  {`${t("Last transaction of")} ${formatCurrency(
                    Number(
                      reservation?.transactions?.[(reservation?.transactions?.length ?? 0) - 1]
                        ?.amount ?? "0",
                    ),
                    cruise?.pricing?.[0]?.currency ?? "USD",
                  )} ${t("was")} ${dayjs(reservation?.transactions?.[(reservation?.transactions?.length ?? 0) - 1]?.made_on ?? null).format(date_format)}`}
                </p>
              </div>

              <div className={styles.tableWrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>{t("transaction id")}</th>
                      <th>{t("amount")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(reservation?.transactions ?? []).map((item) => {
                      const { card_clearance, amount } = item;

                      return (
                        <tr key={nanoid()}>
                          <td>
                            <p>{card_clearance?.transaction_id ?? ""}</p>
                          </td>

                          <td>
                            <p>
                              {formatCurrency(
                                Number(amount ?? "0"),
                                cruise?.pricing?.[0]?.currency ?? "USD",
                              )}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

export default PaymentConfirmation;
