import { Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";
import { countryCodeToFullName } from "@utils/helpers/country";

function isValidLocale(locale: string): boolean {
  try {
    new Intl.DateTimeFormat(locale);

    return true;
  } catch (e) {
    return false;
  }
}

export function CruiseDurationAndDestination() {
  const { cruise } = useTypedSelector((state) => state.search);
  const { app_language } = useTypedSelector((state) => state.environment);

  const nightsAndEmbark = useMemo(() => {
    if (!cruise || !cruise.cruise.nights || !cruise.embark) {
      return "";
    }

    const embarkDate = new Date(cruise.embark);

    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };

    const locale = isValidLocale(app_language) ? app_language : "en-US";
    const formattedDate = embarkDate.toLocaleDateString(locale, options);

    return `${cruise.cruise.nights} nights • ${formattedDate}`;
  }, [cruise, app_language]);

  const routeDescription = useMemo(() => {
    if (!cruise?.cruise?.itinerary || cruise.cruise.itinerary.length < 2) {
      return "";
    }

    const itinerary = cruise.cruise.itinerary.filter((item) => item.pre_post_cruise === "cruise");

    if (itinerary.length < 2) {
      return "";
    }

    const firstStop = itinerary[0];
    const lastStop = itinerary[itinerary.length - 1];

    const startPort = firstStop.port;
    const endPort = lastStop.port;

    const startCountry = countryCodeToFullName(firstStop.country);
    const endCountry = countryCodeToFullName(lastStop.country);

    return `Cruise from ${startPort}, ${startCountry} to ${endPort}, ${endCountry}`;
  }, [cruise]);

  return (
    <Stack direction="column" spacing={2} mt={2}>
      <Stack direction="row" spacing={2}>
        {cruise?.ship?.brand?.logo && (
          <Box
            component="img"
            src={cruise?.ship?.brand?.logo}
            alt="brand logo"
            sx={{ width: { xs: 90, md: 110 }, height: "auto" }}
          />
        )}

        {nightsAndEmbark && (
          <Typography component="p" variant="smallHeader" color="text-dark">
            {nightsAndEmbark}
          </Typography>
        )}
      </Stack>

      {routeDescription && (
        <Typography component="p" variant="paragraphTitle" color="text-primary">
          {routeDescription}
        </Typography>
      )}
    </Stack>
  );
}
