import React, { useMemo } from "react";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import RootLayoutAWX from "@componentsAWX/layouts/Root";
import RootLayoutCRT from "@componentsCRT/layouts/Root";
import RootGuard from "@componentsV1/guards/RootGuard";
import RootLayoutV1 from "@componentsV1/layouts/Root";
import { useTypedSelector } from "@store/store";
import privateRoutes from "@utils/constants/router";
import { LayoutType, ROOT, UIVersion } from "@utils/constants/routes";

const LAYOUTS_BY_VERSION: Record<
  UIVersion,
  React.FC<
    React.PropsWithChildren<{
      type: LayoutType;
      children: React.ReactNode;
    }>
  >
> = {
  v1: RootLayoutV1,
  crt: RootLayoutCRT,
  awx: RootLayoutAWX,
};

function Navigation() {
  const { ui_version, payment_provider } = useTypedSelector((state) => state.environment);

  const routes = useMemo(
    () => privateRoutes(ui_version, payment_provider),
    [ui_version, payment_provider],
  );

  return (
    <Router>
      <RootGuard>
        <Routes>
          {Object.entries(routes).map(([routeKey, route]) => {
            const { element: Element, layout, uiVersion } = route;

            const RootLayout = LAYOUTS_BY_VERSION[uiVersion];
            const CorrectElement = Element || "div";

            return (
              <Route
                key={routeKey}
                path={routeKey}
                element={
                  <RootLayout type={layout}>
                    <CorrectElement />
                  </RootLayout>
                }
              />
            );
          })}

          <Route path="*" element={<Navigate to={ROOT} />} />
        </Routes>
      </RootGuard>
    </Router>
  );
}

export default Navigation;
