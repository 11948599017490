import { useEffect, useState } from "react";

import LoadingContainer from "@componentsV1/containers/LoadingContainer";
import { useQueryParams } from "@hooks/useQueryParams";
import { useLazySearchAgencyQuery } from "@store/services/AgencyService";
import { updateEnvironmentValues } from "@store/slices/environmentSlice";
import { useTypedDispatch } from "@store/store";

function OAuthGuard({ children }: React.PropsWithChildren) {
  const dispatch = useTypedDispatch();
  const [searchAgency] = useLazySearchAgencyQuery();

  const [isLoading, setIsLoading] = useState(true);

  const { oagency, oagent } = useQueryParams();

  const initAgency = async () => {
    try {
      if (oagency) {
        const { data } = await searchAgency(oagency ?? "");

        if (data) {
          const { telephone, email, name } = data;

          dispatch(
            updateEnvironmentValues([
              ["header_telephone_number", telephone],
              ["footer_email_address", email],
              ["footer_address", name],
            ]),
          );
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await initAgency();
    })();
  }, [oagency, oagent]);

  return (
    <div style={{ paddingTop: isLoading ? "1rem" : "0" }}>
      <LoadingContainer isLoading={isLoading}>
        <div>{children}</div>
      </LoadingContainer>
    </div>
  );
}

export default OAuthGuard;
