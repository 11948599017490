import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Collapsible from "@componentsV1/shared/Collapsible";
import { useTypedSelector } from "@store/store";
import { formatCurrency } from "@utils/helpers/currency";

import styles from "./index.module.scss";

function BookingDetails() {
  const { t } = useTranslation();

  const { cruise } = useTypedSelector((state) => state.search);
  const { date_format } = useTypedSelector((state) => state.environment);
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { payment_schedule } = useTypedSelector((state) => state.pricing);

  const [expanded, setExpanded] = useState(true);

  const totalPrice = useMemo(() => {
    const totalSum = Object.values(rooms ?? {}).reduce((accumulator, currentItem) => {
      const totalPriceAsNumber = parseFloat(
        (currentItem.pricing?.total_price ?? "").replace(",", ""),
      );

      const nccf =
        currentItem.pricing?.guests?.reduce((accNccf, currentNccf) => {
          const nccfAsNumber = parseFloat((currentNccf?.nccf ?? "").replace(",", ""));

          return accNccf + nccfAsNumber;
        }, 0) ?? 0;

      return accumulator + totalPriceAsNumber + nccf;
    }, 0);

    return formatCurrency(totalSum, cruise?.pricing?.[0]?.currency ?? "USD");
  }, [rooms]);

  return (
    <Collapsible
      expanded={expanded}
      onExpand={setExpanded}
      renderHeader={() => <p className={styles.header}>{t("booking details")}</p>}
    >
      <div className={styles.body}>
        <p className={styles.name}>{cruise?.cruise.name}</p>

        <table>
          <tbody>
            <tr>
              <td>
                <p className={styles.tableHeading}>{t("embarks")}</p>
              </td>

              <td>
                <p className={styles.tableValue}>
                  {dayjs(cruise?.embark ?? "").format(date_format)}
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p className={styles.tableHeading}>{t("debarks")}</p>
              </td>

              <td>
                <p className={styles.tableValue}>
                  {dayjs(cruise?.embark)
                    .add(cruise?.cruise.nights ?? 1, "days")
                    .format(date_format)}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <hr className={styles.line} />

        {Object.entries(rooms ?? {}).map(([roomKey, room]) => {
          const { grade, guestsNumber, pricing } = room;

          return (
            <div key={nanoid()} className={styles.room}>
              <p className={styles.roomNumber}>{`${t("stateroom")} ${roomKey}`}</p>

              <p className={styles.roomName}>{grade?.name ?? ""}</p>

              <table>
                <tbody>
                  <tr>
                    <td>
                      <p className={styles.tableHeading}>{t("guests")}</p>
                    </td>

                    <td>
                      <p className={styles.tableValue}>{`${guestsNumber} ${t("adult(s)")}`}</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr className={styles.line} />

              <div className={styles.guests}>
                {(pricing?.guests ?? []).map((guest) => {
                  const { guest: guestNumber, total_price, gft, total_fare } = guest;

                  return (
                    <div key={guestNumber} className={styles.guest}>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <p className={classNames(styles.tableHeading, styles.guestHeading)}>
                                {t("fare")}
                              </p>
                            </td>

                            <td>
                              <p className={classNames(styles.tableValue, styles.guestValue)}>
                                {formatCurrency(
                                  total_fare ?? 0,
                                  cruise?.pricing?.[0]?.currency ?? "USD",
                                )}
                              </p>
                            </td>
                          </tr>

                          {!!+gft && (
                            <tr>
                              <td>
                                <p className={classNames(styles.tableHeading, styles.guestHeading)}>
                                  {t("tax")}
                                </p>
                              </td>

                              <td>
                                <p className={classNames(styles.tableValue, styles.guestValue)}>
                                  {formatCurrency(gft, cruise?.pricing?.[0]?.currency ?? "USD")}
                                </p>
                              </td>
                            </tr>
                          )}

                          <tr>
                            <td>
                              <p
                                className={styles.tableHeading}
                              >{`${t("guest")} ${guestNumber} ${t("subtotal")}`}</p>
                            </td>

                            <td>
                              <p className={styles.tableValue}>
                                {formatCurrency(
                                  total_price,
                                  cruise?.pricing?.[0]?.currency ?? "USD",
                                )}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <hr className={styles.line} />
                    </div>
                  );
                })}
              </div>

              <table>
                <tbody>
                  <tr>
                    <td>
                      <p
                        className={styles.tableHeading}
                      >{`${t("stateroom")} ${roomKey} ${t("fare")}:`}</p>
                    </td>

                    <td>
                      <p className={styles.tableValue}>
                        {formatCurrency(
                          room.pricing?.total_price ?? "0",
                          cruise?.pricing?.[0]?.currency ?? "USD",
                        )}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr className={styles.line} />
            </div>
          );
        })}

        <table>
          <tbody>
            <tr>
              <td>
                <p className={classNames(styles.tableHeading, styles.totalPriceHeading)}>
                  {t("total fare:")}
                </p>
              </td>

              <td>
                <p className={classNames(styles.tableValue, styles.totalPriceValue)}>
                  {totalPrice}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <hr className={styles.line} />

        <div>
          <p className={styles.roomNumber}>{t("payment schedule")}</p>

          <p className={classNames(styles.tableValue, styles.scheduleTableValue)}>
            {t("deposit & payment option")}
          </p>
        </div>

        <table>
          <tbody>
            {Object.entries(payment_schedule).map(([scheduleKey, scheduleItem]) => {
              const { date, amount } = scheduleItem;
              const isToday = dayjs(date).isSame(dayjs(), "day");

              const title = isToday ? t("today") : dayjs(date).format(date_format);

              return (
                <tr key={scheduleKey}>
                  <td>
                    <p className={styles.tableHeading}>{title}</p>
                  </td>
                  <td>
                    <p className={styles.tableValue}>
                      {formatCurrency(amount, cruise?.pricing?.[0]?.currency ?? "USD")}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Collapsible>
  );
}

export default BookingDetails;
