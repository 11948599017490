import { AccordionProps, ComponentsOverrides, Theme } from "@mui/material";

declare module "@mui/material/Accordion" {
  interface AccordionPropsColorOverrides {
    "bg-primary": true;
    "bg-secondary": true;
    "bg-dark": true;
    "bg-button": true;
    "text-primary": true;
    "text-light": true;
    "text-dark": true;
  }
}

interface IMuiAccordion {
  defaultProps: Partial<AccordionProps>;
  styleOverrides: ComponentsOverrides<Theme>["MuiAccordion"];
}

export const MuiAccordion: IMuiAccordion = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      border: "2px solid",
      borderColor: "#1B4E65",
      borderTopLeftRadius: `${theme.toPx(theme.customShape.largeRadius)} !important`,
      borderTopRightRadius: `${theme.toPx(theme.customShape.largeRadius)} !important`,
      borderBottomLeftRadius: `${theme.toPx(theme.customShape.largeRadius)} !important`,
      borderBottomRightRadius: `${theme.toPx(theme.customShape.largeRadius)} !important`,
    }),
  },
};
