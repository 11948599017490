import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import { t } from "i18next";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";
import { formatCurrency } from "@utils/helpers/currency";

import styles from "./index.module.scss";

function StateroomPriceBreakdown({ roomNumber }: { roomNumber: number }) {
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { cruise } = useTypedSelector((state) => state.search);

  const room = useMemo(() => {
    if (rooms && roomNumber) {
      return rooms[roomNumber];
    }

    return undefined;
  }, [rooms, roomNumber]);

  const pricing = useMemo(() => {
    if (!room?.guestsNumber || !room?.grade) {
      return { prices: [], fare: 0, tax: 0, total: 0 };
    }

    const { grade, guestsNumber, fare } = room;

    const tax = fare?.prices.find((item) => item.grade === grade.code)?.gft
      ? Number(fare?.prices.find((item) => item.grade === grade.code)?.gft)
      : 0;

    const pricesByGuestNumber: Record<number, number> = {
      1: grade.single,
      2: grade.double,
      3: grade.triple,
      4: grade.quadruple,
    };

    const prices = [];

    const roomPricing = {
      fare: pricesByGuestNumber[guestsNumber] - tax,
      total: pricesByGuestNumber[guestsNumber],
      tax,
    };

    for (let i = 0; i < room.guestsNumber; i += 1) {
      prices.push({
        guestNumber: i + 1,
        tax: roomPricing.tax,
        fare: roomPricing.fare,
        total: roomPricing.total,
      });
    }

    return { prices, ...roomPricing };
  }, [room]);

  return (
    <div className={styles.body}>
      <div key={nanoid()} className={styles.room}>
        <table>
          <tbody>
            <tr>
              <td>
                <p className={styles.tableHeading}>{t("guests")}</p>
              </td>

              <td>
                <p className={styles.tableValue}>
                  {`${room?.guestsNumber ?? "N/A"} ${t("adult(s)")}`}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <hr className={styles.line} />

        <div className={styles.guests}>
          {pricing.prices.map((guest) => {
            const { guestNumber, fare, tax, total } = guest;

            return (
              <div key={guestNumber} className={styles.guest}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p className={classNames(styles.tableHeading, styles.guestHeading)}>
                          {t("fare")}
                        </p>
                      </td>

                      <td>
                        <p className={classNames(styles.tableValue, styles.guestValue)}>
                          {formatCurrency(fare ?? 0, cruise?.pricing?.[0]?.currency ?? "USD")}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className={classNames(styles.tableHeading, styles.guestHeading)}>
                          {t("tax")}
                        </p>
                      </td>

                      <td>
                        <p className={classNames(styles.tableValue, styles.guestValue)}>
                          {formatCurrency(tax, cruise?.pricing?.[0]?.currency ?? "USD")}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p
                          className={styles.tableHeading}
                        >{`${t("guest")} ${guestNumber} ${t("subtotal")}`}</p>
                      </td>

                      <td>
                        <p className={styles.tableValue}>
                          {formatCurrency(total, cruise?.pricing?.[0]?.currency ?? "USD")}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <hr className={styles.line} />
              </div>
            );
          })}
        </div>

        <table>
          <tbody>
            <tr>
              <td>
                <p
                  className={styles.tableHeading}
                >{`${t("stateroom")} ${roomNumber} ${t("fare")}:`}</p>
              </td>

              <td>
                <p className={styles.tableValue}>
                  {formatCurrency(
                    `${(pricing.total ?? 0) * (room?.guestsNumber ?? 1)}`,
                    cruise?.pricing?.[0]?.currency ?? "USD",
                  )}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <hr className={styles.line} />
      </div>
    </div>
  );
}

export default StateroomPriceBreakdown;
