import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";

import LoadingIndicator from "@componentsV1/shared/LoadingIndicator";
import SvgIcon from "@componentsV1/shared/SvgIcon";
import useOnClickOutside from "@hooks/useClickOutside";

import styles from "./index.module.scss";

interface ISearchInputProps {
  className?: string;
  value: string;
  valueKey: string;
  displayKey: string;
  results: Array<Record<string, string>> | null | [];
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  isLoading?: boolean;
  isRequired?: boolean;
  readOnly?: boolean;
  isMultiple: boolean;
  showError?: boolean;
  onClick?: () => void;
  changes?: Array<Record<string, string>>;
  onChosenChange?: ({
    items,
    value,
    valueKey,
  }: {
    items?: Array<Record<string, string>>;
    value: Record<string, string>;
    valueKey: string;
  }) => void;
  onChange?: ({ value, valueKey }: { value: string; valueKey?: string }) => void;
}

function SearchInput({
  className,
  value,
  valueKey,
  displayKey,
  results,
  label,
  placeholder,
  errorMessage,
  isLoading,
  isRequired,
  readOnly,
  isMultiple,
  showError,
  onClick,
  onChosenChange,
  onChange,
  changes,
}: ISearchInputProps) {
  const [internalValue, setInternalValue] = useState(value);
  const [internalResults, setInternalResults] = useState(results);
  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const [chosenItems, setChosenItems] = useState<Array<Record<string, string>>>([]);

  const id = useMemo(nanoid, []);

  const outsideRef = useRef(null);

  const timeoutIdRef: MutableRefObject<ReturnType<typeof setTimeout> | null> = useRef(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timeoutIdRef.current as ReturnType<typeof setTimeout>);
    setInternalValue(e.target.value);

    timeoutIdRef.current = setTimeout(() => {
      if (onChange) {
        onChange({ value: e.target.value, valueKey });
      }
    }, 500);
  };

  const handleChose = (item: Record<string, string>) => () => {
    if (isMultiple) {
      const newChosenItems = [...chosenItems, item];

      setChosenItems(newChosenItems);

      if (onChosenChange) {
        onChosenChange({ items: newChosenItems, value: item, valueKey });
      }
    } else if (onChosenChange) {
      onChosenChange({ value: item, valueKey });
      setIsResultsVisible(false);
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleFocus = () => {
    setIsResultsVisible(true);
  };

  const handleClickOutside = () => {
    if (isLoading) {
      return;
    }

    setIsResultsVisible(false);
  };

  const handleRemoveChosen = (item: Record<string, string>) => () => {
    const newChosen = chosenItems.filter((el) => el[displayKey] !== item[displayKey]);

    setChosenItems(newChosen);

    if (onChosenChange) {
      onChosenChange({ items: newChosen, value: item, valueKey });
    }
  };

  useEffect(() => {
    setChosenItems(changes ?? []);
  }, [changes]);

  useEffect(() => {
    setInternalValue(value);
  }, [value, chosenItems]);

  useEffect(() => {
    if (isLoading) {
      setIsResultsVisible(true);
    }
  }, [isLoading, results]);

  useEffect(() => {
    const chosenDisplayKeys = chosenItems.map((el) => el[displayKey]);

    const filteredResults = (results ?? []).filter(
      (el) => !chosenDisplayKeys.includes(el[displayKey]),
    );

    setInternalResults(filteredResults);
  }, [results, chosenItems]);

  useOnClickOutside(outsideRef, handleClickOutside);

  return (
    <div className={classNames(styles.container, className)}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
          {isRequired && <span className={styles.asterisk}>*</span>}
        </label>
      )}

      <div className={styles.fieldContainer}>
        <input
          id={id}
          className={classNames(styles.field, {
            [styles.field_error]: !!errorMessage,
          })}
          value={internalValue}
          placeholder={placeholder}
          readOnly={readOnly}
          onClick={handleClick}
          onChange={handleChange}
          onFocus={handleFocus}
        />

        <div
          className={classNames(styles.chosen, {
            [styles.chosen_visible]: chosenItems.length,
          })}
        >
          {chosenItems.map((el) => {
            return (
              <div key={nanoid()} className={styles.chosenItem}>
                <p>{el[displayKey]}</p>
                <SvgIcon
                  type="plus"
                  className={styles.closeIcon}
                  onClick={handleRemoveChosen(el)}
                />
              </div>
            );
          })}
        </div>

        <div
          ref={outsideRef}
          className={classNames(styles.results, {
            [styles.results_visible]: isResultsVisible,
          })}
        >
          {isLoading && <LoadingIndicator className={styles.loader} width={24} height={24} />}

          {!isLoading &&
            internalResults &&
            internalResults?.length > 0 &&
            internalResults.map((el) => (
              <div
                key={nanoid()}
                className={styles.resultsItem}
                role="option"
                aria-selected
                tabIndex={0}
                onClick={handleChose(el)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") handleChose(el)();
                }}
              >
                <p>{el[displayKey]}</p>
              </div>
            ))}
        </div>
      </div>

      {showError ? <span className={styles.error}>{errorMessage}</span> : null}
    </div>
  );
}

SearchInput.defaultProps = {
  isLoading: false,
  className: "",
  label: "",
  placeholder: "",
  errorMessage: "",
  isRequired: false,
  readOnly: "",
  showError: true,
  changes: [],
  onClick: () => null,
  onChange: () => null,
  onChosenChange: () => null,
};

export default SearchInput;
