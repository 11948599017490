import classNames from "classnames";

import SvgIcon from "../SvgIcon";

import styles from "./index.module.scss";

interface AlertProps {
  message: string;
  className?: string;
}

function Alert({ message, className }: AlertProps) {
  return (
    <div className={classNames(styles.alert, className ?? "")}>
      <SvgIcon type="info" className={styles.icon} />

      <span>{message}</span>
    </div>
  );
}

export default Alert;
