import Checkbox from "@componentsV1/shared/Checkbox";
import { useTypedSelector } from "@store/store";

import styles from "./index.module.scss";

interface ILegalDataProps {
  terms_and_conditions_checked: boolean;
  participant_release_agreement_checked: boolean;
  supplemental_terms_checked: boolean;
  guest_ticket_contract_checked: boolean;
  show_cancellation_policy_checked: boolean;
  liability_and_cancellation_checked: boolean;
  onChange: ({ value, valueKey }: { value: boolean; valueKey: string }) => void;
}

function LegalData({
  terms_and_conditions_checked,
  participant_release_agreement_checked,
  supplemental_terms_checked,
  guest_ticket_contract_checked,
  show_cancellation_policy_checked,
  liability_and_cancellation_checked,
  onChange,
}: ILegalDataProps) {
  const {
    privacy_policy,
    show_cancellation_policy,
    terms_and_conditions,
    liability_and_cancellation,
    participant_release_agreement,
    supplemental_terms,
    guest_ticket_contract,
  } = useTypedSelector((state) => state.environment);

  const handleChange = ({ value, valueKey }: { value: boolean; valueKey: string }) =>
    onChange({ value, valueKey });

  function parseTextWithLinks(text: string) {
    const linkRegex = /\[([^\]]+)]\(([^)]+)\)/g;

    const parsedText = text.replace(linkRegex, (match, incomingText, url) => {
      return `<a class=${styles.link} href="${url}" target="_blank" rel="noreferrer">${incomingText}</a>`;
    });

    return parsedText;
  }

  return (
    <div className={styles.container}>
      {privacy_policy && (
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html: parseTextWithLinks(privacy_policy),
            }}
          />
        </p>
      )}

      {show_cancellation_policy && (
        <Checkbox
          value={show_cancellation_policy_checked}
          valueKey="show_cancellation_policy"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(show_cancellation_policy),
                }}
              />
            </>
          }
        />
      )}

      {terms_and_conditions && (
        <Checkbox
          value={terms_and_conditions_checked}
          valueKey="terms_and_conditions"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(terms_and_conditions),
                }}
              />
            </>
          }
        />
      )}

      {participant_release_agreement && (
        <Checkbox
          value={participant_release_agreement_checked}
          valueKey="participant_release_agreement"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(participant_release_agreement),
                }}
              />
            </>
          }
        />
      )}

      {supplemental_terms && (
        <Checkbox
          value={supplemental_terms_checked}
          valueKey="supplemental_terms"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(supplemental_terms),
                }}
              />
            </>
          }
        />
      )}

      {guest_ticket_contract && (
        <Checkbox
          value={guest_ticket_contract_checked}
          valueKey="guest_ticket_contract"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(guest_ticket_contract),
                }}
              />
            </>
          }
        />
      )}

      {liability_and_cancellation && (
        <Checkbox
          value={liability_and_cancellation_checked}
          valueKey="liability_and_cancellation"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(liability_and_cancellation),
                }}
              />
            </>
          }
        />
      )}
    </div>
  );
}

export default LegalData;
