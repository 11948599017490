import { Box } from "@mui/material";

import { Header } from "@componentsCRT/base/Header";
import AuthLayout from "@componentsCRT/layouts/Auth";
import SearchLayout from "@componentsCRT/layouts/Search";
import { BACK_TO_TOP_ANCHOR } from "@utils/constants/layout";
import { LayoutType } from "@utils/constants/routes";

const LAYOUTS_BY_TYPE: Record<LayoutType, React.FC<React.PropsWithChildren>> = {
  auth: AuthLayout,
  search: SearchLayout,
};

interface IRootLayoutProps {
  type: LayoutType;
  children: React.ReactNode;
}

function RootLayout({ type, children }: IRootLayoutProps) {
  const Layout = LAYOUTS_BY_TYPE[type];

  return (
    <Box
      id={BACK_TO_TOP_ANCHOR}
      component="div"
      sx={{
        width: "100vw",
        minHeight: "100vh",
        bgcolor: (theme) => theme.palette["bg-primary"].main,
      }}
    >
      <Header />

      <main>
        <section>
          <Layout>{children}</Layout>
        </section>
      </main>
    </Box>
  );
}

export default RootLayout;
