import { Box, Link, Typography, Stack, Divider, useMediaQuery } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";

export function TripSummary() {
  const { cruise } = useTypedSelector((state) => state.search);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const summaryInfo = useMemo(() => {
    if (!cruise?.cruise?.descriptions) {
      return [];
    }

    const packageStarts = cruise.cruise.descriptions.find(
      (desc) => desc.category === "Package Start",
    );

    const cruiseStarts = cruise.cruise.descriptions.find(
      (desc) => desc.category === "Cruise Start",
    );

    const cruiseEnds = cruise.cruise.descriptions.find((desc) => desc.category === "Cruise End");

    const packageEnds = cruise.cruise.descriptions.find((desc) => desc.category === "Package Ends");

    return [
      {
        title: "PACKAGE STARTS",
        dateInfo: packageStarts ? packageStarts.description : "",
      },
      {
        title: "CRUISE STARTS",
        dateInfo: cruiseStarts ? cruiseStarts.description : "",
      },
      {
        title: "CRUISE ENDS",
        dateInfo: cruiseEnds ? cruiseEnds.description : "",
      },
      {
        title: "PACKAGE ENDS",
        dateInfo: packageEnds ? packageEnds.description : "",
      },
    ];
  }, [cruise]);

  return (
    <Box mt={{ xs: 4, md: 8 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography component="span" variant="sectionHeader" color="text-primary">
          Trip Summary
        </Typography>

        <Typography component={Link} variant="footnote" href="#">
          View Summary
        </Typography>
      </Box>

      <Stack
        spacing={{ xs: 2, md: 4 }}
        mt={2}
        direction={{ xs: "column", md: "row" }}
        divider={isMobile ? null : <Divider orientation="vertical" flexItem />}
      >
        {summaryInfo.map(({ title, dateInfo }) => (
          <Box
            key={title}
            sx={{
              width: { xs: "100%", md: "25%" },
            }}
          >
            <Typography
              component="p"
              variant="footnote"
              color="text-dark"
              fontWeight={700}
              mb={0.5}
            >
              {title}
            </Typography>

            <Typography component="p" variant="footnote" fontWeight={400} color="text-dark">
              {dateInfo}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
