import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

import { store } from "@store/store";

const prepareHeaders = (headers: Headers) => {
  const { token } = store.getState().session;

  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }

  return headers;
};

const sessionQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = (
  args,
  WebApi,
  extraOptions,
) => {
  const baseUrl = store.getState().environment.token_domain;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

const posQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = (
  args,
  WebApi,
  extraOptions,
) => {
  const baseUrl = store.getState().environment.pos_domain;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

const searchQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = (
  args,
  WebApi,
  extraOptions,
) => {
  const baseUrl = store.getState().environment.search_domain;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

const agencyQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_CABIN_SELECT_API_DOMAIN as string,
  prepareHeaders: (headers) => {
    const { token } = store.getState().session;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
  },
});

const environmentQuery = fetchBaseQuery({
  baseUrl: `${window.location.protocol}//${window.location.hostname}`,
});

const paymentQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = (
  args,
  WebApi,
  extraOptions,
) => {
  const baseUrl = store.getState().environment.payment_domain;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

const reservationQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = (
  args,
  WebApi,
  extraOptions,
) => {
  const baseUrl = store.getState().environment.api_domain;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

export const sessionApi = createApi({
  reducerPath: "SessionAPI",
  baseQuery: sessionQuery,
  endpoints: () => ({}),
});

export const posApi = createApi({
  reducerPath: "PosAPI",
  baseQuery: posQuery,
  endpoints: () => ({}),
});

export const searchApi = createApi({
  reducerPath: "SearchAPI",
  baseQuery: searchQuery,
  endpoints: () => ({}),
});

export const agencyApi = createApi({
  reducerPath: "AgencyAPI",
  baseQuery: agencyQuery,
  endpoints: () => ({}),
});

export const environmentApi = createApi({
  reducerPath: "EnvironmentAPI",
  baseQuery: environmentQuery,
  endpoints: () => ({}),
});

export const localesApi = createApi({
  reducerPath: "LocalesAPI",
  baseQuery: environmentQuery,
  endpoints: () => ({}),
});

export const paymentApi = createApi({
  reducerPath: "PaymentAPI",
  baseQuery: paymentQuery,
  endpoints: () => ({}),
});

export const reservationApi = createApi({
  reducerPath: "ReservationAPI",
  baseQuery: reservationQuery,
  endpoints: () => ({}),
});

const api = {
  session: sessionApi,
  pos: posApi,
  search: searchApi,
  agency: agencyApi,
  environment: environmentApi,
  locales: localesApi,
  payment: paymentApi,
  reservation: reservationApi,
};

export default api;
