import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";

export function SpecialValidityDates() {
  const { cruise } = useTypedSelector((state) => state.search);
  const { date_format } = useTypedSelector((state) => state.environment);

  const { valid_from, valid_to } = useMemo(() => {
    if (!cruise?.valid_from || !cruise.valid_to) {
      return { valid_from: null, valid_to: null };
    }

    const valid_from = dayjs(cruise.valid_from).format(date_format);
    const valid_to = dayjs(cruise.valid_to).format(date_format);

    return { valid_from, valid_to };
  }, [cruise]);

  if (!valid_from || !valid_to) {
    return null;
  }

  return (
    <Box mt={{ xs: 4, md: 8 }}>
      <Typography component="h2" variant="subtitle" color="text-primary">
        Special Validity Dates
      </Typography>

      <Typography
        component="p"
        variant="footnote"
        color="text-dark"
        mt={{ xs: 2, md: 4 }}
      >{`Special is valid from ${valid_from} to ${valid_to} or until sold out/withdrawn.`}</Typography>
    </Box>
  );
}
