import { reservationApi } from "@store/services/apiSingleton";
import { ReservationInterface } from "@store/slices/reservationSlice";
import { store } from "@store/store";

interface RetrieveReservationParams {
  pnr: string;
  channel_partner: string;
  agency: string;
}

interface ConfirmReservationRequest {
  pnr: string;
  transactions: Array<{
    transaction_type: "creditcard";
    made_on?: string;
    made_by?: "Website";
    amount?: number;
    currency?: string;
    card_clearance: {
      clearance_system: "airwallex";
      auth_code: string;
      transaction_id: string;
      other: {
        card_type: string;
        last_four: string;
        transaction_id: string;
        intent_id: string;
      };
    };
  }>;
}

export const ReservationApi = reservationApi.injectEndpoints({
  endpoints: (builder) => ({
    retrieveReservation: builder.query<ReservationInterface, RetrieveReservationParams>({
      query: (params) => ({
        url: `/res/reservation-list/${params.pnr}/?channel=CS&channel_partner=${params.channel_partner}&agency=${params.agency}`,
        method: "GET",
      }),
    }),
    confirmReservation: builder.query<null, ConfirmReservationRequest>({
      query: (request) => {
        const { api_agency: agency, api_agent: agent } = store.getState().environment;

        const { pnr, ...body } = request;

        return {
          url: `/res/reservation/${pnr}/`,
          method: "PUT",
          body: {
            channel: "CS",
            agency,
            agent,
            ...body,
          },
        };
      },
    }),
  }),
});

export const { useLazyRetrieveReservationQuery, useLazyConfirmReservationQuery } = ReservationApi;
