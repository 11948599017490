import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Button from "@componentsV1/shared/Button";
import RangeSlider from "@componentsV1/shared/RangeSlider";
import { selectSearchCalculatePrice, selectSearchFilter } from "@store/selectors";
import {
  sortName,
  sortDate,
  filterRangePrice,
  SORT_TYPE,
  sortPrice,
} from "@store/slices/searchFilterSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";
import { formatCurrency } from "@utils/helpers/currency";

import styles from "./index.module.scss";

function SearchFilterForm() {
  const dispatch = useTypedDispatch();

  const { t } = useTranslation();
  const filter = useTypedSelector(selectSearchFilter);
  const filterPrices = useTypedSelector(selectSearchCalculatePrice);
  const { cruise } = useTypedSelector((state) => state.search);

  const [rangePrice, setRangePrice] = React.useState([...filterPrices]);

  const handleSortName = () => {
    dispatch(sortName(!filter.sortName));
  };

  const handleSortDate = () => {
    dispatch(sortDate(!filter.sortDate));
  };

  const handleSortPrice = () => {
    dispatch(sortPrice(!filter.sortPrice));
  };

  const handleRangePrice = (minPrice: number, maxPrice: number) => {
    dispatch(filterRangePrice([minPrice, maxPrice]));
  };

  useEffect(() => {
    handleRangePrice(filterPrices[0], filterPrices[1]);
  }, [filterPrices]);

  return (
    <div className={classNames(styles.content)}>
      <div className={classNames(styles.rangeSliderContainer)}>
        <p className={classNames(styles.labelRangePrice)}>
          {formatCurrency(rangePrice[0], cruise?.pricing?.[0]?.currency ?? "USD")} -{" "}
          {formatCurrency(rangePrice[1], cruise?.pricing?.[0]?.currency ?? "USD")}
        </p>

        <RangeSlider
          min={filterPrices[0]}
          max={filterPrices[1]}
          onMouseUp={(value) => handleRangePrice(value.min, value.max)}
          onChange={(value) => setRangePrice([value.min, value.max])}
        />
      </div>

      <Button
        className={classNames(styles.button, {
          [styles.activeButton]: filter.sortType === SORT_TYPE.NANE,
        })}
        label={t("sort name: ") + (filter.sortName ? "A-Z" : "Z-A")}
        icon={filter.sortName ? "down" : "up"}
        onClick={handleSortName}
      />

      <Button
        className={classNames(styles.button, {
          [styles.activeButton]: filter.sortType === SORT_TYPE.DATE,
        })}
        label={t("sort date")}
        icon={filter.sortDate ? "down" : "up"}
        onClick={handleSortDate}
      />

      <Button
        className={classNames(styles.button, {
          [styles.activeButton]: filter.sortType === SORT_TYPE.PRICE,
        })}
        label={t("sort price")}
        icon={filter.sortPrice ? "down" : "up"}
        onClick={handleSortPrice}
      />
    </div>
  );
}

function SearchFilter() {
  const { t } = useTranslation();

  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className={classNames(styles.container)}>
      <Button label={t("filter results")} onClick={handleShowFilter} />

      {showFilter && <SearchFilterForm />}
    </div>
  );
}

export default SearchFilter;
