import { useEffect } from "react";

import LoadingContainer from "@componentsV1/containers/LoadingContainer";
import useCookies from "@hooks/useCookies";
import { useQueryParams } from "@hooks/useQueryParams";
import { initApiToken, initEnvironment, initPosSession } from "@store/actions/sessionActions";
import { updateSessionKey, updateToken } from "@store/slices/sessionSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";

function SessionGuard({ children }: React.PropsWithChildren) {
  const dispatch = useTypedDispatch();
  const { getCookie, setCookie } = useCookies();

  const {
    isInitialized: isEnvironmentInitialized,
    api_agent,
    api_agency,
  } = useTypedSelector((state) => state.environment);

  const {
    isInitialized: isSessionInitialized,
    token,
    sessionKey,
  } = useTypedSelector((state) => state.session);

  const { oagency, oagent } = useQueryParams();

  const cookieToken = getCookie("token");
  const cookieSessionKey = getCookie("sessionKey");
  const cookieAgent = getCookie("api_agent");
  const cookieAgency = getCookie("api_agency");

  const paramsChanged = api_agency !== cookieAgency || api_agent !== cookieAgent;

  const initEnvironmentVariables = () => {
    dispatch(initEnvironment({ oagency, oagent }));
  };

  const initToken = () => {
    if (cookieToken) {
      dispatch(updateToken(cookieToken));
    } else {
      dispatch(initApiToken());
    }
  };

  const initAgentSession = () => {
    dispatch(updateSessionKey(cookieSessionKey));
  };

  useEffect(initEnvironmentVariables, [oagency, oagent]);

  useEffect(() => {
    if (isEnvironmentInitialized) {
      initToken();
    }
  }, [isEnvironmentInitialized]);

  useEffect(() => {
    initAgentSession();
  }, []);

  useEffect(() => {
    const shouldCreateSession = Boolean(token) && Boolean(!sessionKey);
    const shouldRecreateSession = Boolean(token) && Boolean(sessionKey) && paramsChanged;

    if (shouldCreateSession || shouldRecreateSession) {
      dispatch(initPosSession());
    }
  }, [token, sessionKey, api_agent, api_agency]);

  useEffect(() => {
    if (api_agent) {
      setCookie("api_agent", api_agent, 3600);
    }

    if (api_agency) {
      setCookie("api_agency", api_agency, 3600);
    }
  }, [api_agent, api_agency]);

  useEffect(() => {
    if (token && !cookieToken) {
      setCookie("token", token, 3600);
    }
  }, [token, cookieToken]);

  useEffect(() => {
    if (sessionKey && !cookieSessionKey) {
      setCookie("sessionKey", sessionKey, 3600);
    }
  }, [sessionKey, cookieSessionKey]);

  return (
    <LoadingContainer isLoading={!isEnvironmentInitialized || !isSessionInitialized}>
      {children}
    </LoadingContainer>
  );
}

export default SessionGuard;
