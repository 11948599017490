import { Box, Typography, Grid2 as Grid } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";
import { formatCurrency } from "@utils/helpers/currency";

export function Pricing() {
  const { cruise } = useTypedSelector((state) => state.search);

  const cabins = useMemo(() => {
    if (!cruise?.ship?.grades || !cruise.pricing) {
      return [];
    }

    const pricesByGrade = new Map<string, { standard: string; single: string }>(
      cruise.pricing.flatMap((pricing) =>
        pricing.prices.map((price) => [
          price.grade,
          { standard: price.standard, single: price.single },
        ]),
      ),
    );

    return cruise.ship.grades
      .filter((grade) => pricesByGrade.has(grade.code) && pricesByGrade.get(grade.code))
      .map((grade) => ({
        name: `${grade.name} Twin Share`,
        description: grade.descriptions?.[0]?.description || "",
        price: pricesByGrade.get(grade.code),
        image: grade.images?.[0] || cruise.ship.brand.logo || "",
      }))
      .sort((a, b) => Number(a?.price?.standard) - Number(b?.price?.standard));
  }, [cruise]);

  if (!cabins.length) {
    return null;
  }

  return (
    <Box>
      <Typography component="p" variant="sectionHeader" color="text-primary" mb={2}>
        Cabins
      </Typography>

      <Grid container spacing={{ xs: 2, md: 4 }}>
        {cabins.map(({ name, description, price, image }) => (
          <Grid size={12} key={name}>
            <Grid
              container
              spacing={{ xs: 2, md: 4 }}
              sx={{
                border: "2px solid",
                borderColor: (theme) => theme.palette["border-color"].main,
                borderRadius: (theme) => theme.toPx(theme.customShape.largeRadius),
                padding: { xs: 2, lg: 4 },
                alignItems: "center",
              }}
            >
              <Grid
                size={{ xs: 12, md: 5 }}
                component="img"
                src={image}
                alt={`${name} cabin`}
                sx={{
                  borderRadius: (theme) => theme.toPx(theme.customShape.largeRadius),
                  width: "100%",
                  height: "auto",
                }}
              />

              <Grid size={{ xs: 12, md: 7 }}>
                <Typography
                  component="p"
                  variant="paragraphTitle"
                  mb={1}
                  textTransform="uppercase"
                  color="text-dark"
                >
                  {name}
                </Typography>

                <Typography component="p" variant="footnote" color="text-dark" mb={1}>
                  {description}
                </Typography>

                <Typography component="p" variant="smallHeader" color="text-dark">
                  From {formatCurrency(Number(price?.standard), cruise?.markets.currency as string)}{" "}
                  Per Person Twin Share
                </Typography>

                {price?.single && price?.single !== "0.00" && (
                  <Typography component="p" variant="smallHeader" color="text-dark">
                    From {formatCurrency(Number(price.single), cruise?.markets.currency as string)}{" "}
                    Single Occupancy
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
