/* eslint-disable @typescript-eslint/no-shadow */
import { createAsyncThunk } from "@reduxjs/toolkit";

import { EnvironmentAPI } from "@store/services/EnvironmentService";
import { PosAPI } from "@store/services/PosService";
import { SessionApi } from "@store/services/SessionService";
import { showToast } from "@store/slices/toastSlice";
import { RootState } from "@store/store";
import * as ENV from "@utils/constants/environment";
import TOAST from "@utils/constants/toast";
import { mapAcfToEnvironment } from "@utils/mappers/environment";

type Address = {
  name: string;
  type: string;
  value: string;
};

type ApiAccount = {
  username: string;
  name: string;
};

export type AgencyDetails = {
  account_type: string;
  address: Address[];
  api_accounts: ApiAccount[];
  channels: null | unknown;
  code: string;
  commission_level: string;
  consortia: string;
  created: string;
  created_by: string;
  credit_limit: string;
  deleted: null | string;
  deleted_by: null | string;
  email: string;
  external_code: string;
  id: number;
  market: string;
  name: string;
  pos_sources: null | unknown;
  publish_attempt_processed: string;
  publish_attempt_status: string;
  published: string;
  published_by: string;
  regulatory: null | unknown;
  sales_area: null | unknown;
  send_customer_emails: boolean;
  telephone: string;
  updated: string;
  updated_by: string;
  uuid: string;
};

interface InitEnvironmentProps {
  oagency?: string | null;
  oagent?: string | null;
  agency?: AgencyDetails | null;
}

export const initEnvironment = createAsyncThunk(
  "session/initEnvironment",
  async ({ oagency, oagent, agency }: InitEnvironmentProps, { dispatch }) => {
    const { data } = await dispatch(EnvironmentAPI.endpoints.initEnvironment.initiate(null));

    let environment = structuredClone(ENV);

    if (data) {
      const { acf } = data;
      const mappedEnvironment = mapAcfToEnvironment(acf);

      environment = { ...environment, ...mappedEnvironment };
    }

    environment = {
      ...environment,
      api_agent: oagent ?? environment.api_agent,
      api_agency: oagency ?? environment.api_agency,
      header_telephone_number: agency?.telephone ?? environment.header_telephone_number,
      footer_email_address: agency?.email ?? environment.footer_email_address,
    };

    return environment;
  },
);

export const initApiToken = createAsyncThunk(
  "session/initApiToken",
  async (_, { getState, dispatch }) => {
    const { api_username: username, api_password: password } = (getState() as RootState)
      .environment;

    const { data } = await dispatch(
      SessionApi.endpoints.initApiToken.initiate({
        username,
        password,
      }),
    );

    if (!data) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: "Wrong credentials",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );
    }

    return data;
  },
);

export const initPosSession = createAsyncThunk(
  "session/initPosSession",
  async (_, { getState, dispatch }) => {
    const { api_agent: agent, api_agency: agency } = (getState() as RootState).environment;

    const { data } = await dispatch(PosAPI.endpoints.initPosSession.initiate({ agent, agency }));

    return data;
  },
);
