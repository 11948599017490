import { TabsProps, ComponentsOverrides } from "@mui/material";

declare module "@mui/material/Tabs" {
  interface TabsPropsColorOverrides {
    "bg-primary": true;
    "bg-secondary": true;
    "bg-dark": true;
    "bg-button": true;
    "text-primary": true;
    "text-light": true;
    "text-dark": true;
  }
}

interface IMuiTabs {
  defaultProps: Partial<TabsProps>;
  styleOverrides: ComponentsOverrides["MuiTabs"];
}

export const MuiTabs: IMuiTabs = {
  defaultProps: {},
  styleOverrides: {
    root: {
      "& .MuiTabs-indicator": {
        display: "none",
      },
    },
  },
};
