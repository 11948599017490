export const {
  REACT_APP_PRIMARY_COLOR: primary_color,
  REACT_APP_SECONDARY_COLOR: secondary_color,
  REACT_APP_TEXT_PRIMARY_COLOR: text_primary_color,
  REACT_APP_TEXT_SECONDARY_COLOR: text_secondary_color,
  REACT_APP_INPUT_BORDER_COLOR: input_border_color,
  REACT_APP_INPUT_BACKGROUND_COLOR: input_background_color,
  REACT_APP_INPUT_PLACEHOLDER_COLOR: input_placeholder_color,
  REACT_APP_INPUT_TEXT_COLOR: input_text_color,
  REACT_APP_INPUT_FOCUS_BORDER_COLOR: input_focus_border_color,
  REACT_APP_ERROR_COLOR: error_color,
  REACT_APP_MENU_BACKGROUND_COLOR: menu_background_color,
  REACT_APP_MENU_TEXT_COLOR: menu_text_color,
  REACT_APP_ELEMENT_BACKGROUND_PRIMARY: element_background_primary,
  REACT_APP_ELEMENT_BACKGROUND_SECONDARY: element_background_secondary,
  REACT_APP_ELEMENT_BACKGROUND_DARK: element_background_dark,
  REACT_APP_BACKGROUND_COLOR: background_color,
  REACT_APP_LINK_COLOR: link_color,
  REACT_APP_SECTION_BACKGROUND_COLOR: section_background_color,
  REACT_APP_SHADOW_COLOR: shadow_color,
  REACT_APP_SUBTEXT_COLOR: subtext_color,
  REACT_APP_DELIMITTER_LINE_COLOR: delimitter_line_color,
  REACT_APP_SVG_ICONS_PRIMARY: svg_icons_primary,
  REACT_APP_SVG_ICONS_SECONDRY: svg_icons_secondary,
  REACT_APP_SEARCH_BUTTON_BACKGROUND: search_button_background,
  REACT_APP_SITE_LOGO: site_logo,
  REACT_APP_HEADER_BACKGROUND: header_background,
  REACT_APP_FOOTER_BACKGROUND: footer_background,
  REACT_APP_FOOTER_FONT_COLOR: footer_font_color,
  REACT_APP_CABIN_SELECT_API_USERNAME: api_username,
  REACT_APP_CABIN_SELECT_API_PASSWORD: api_password,
  REACT_APP_CABIN_SELECT_API_AGENCY: api_agency,
  REACT_APP_CABIN_SELECT_SEARCH_DOMAIN: search_domain,
  REACT_APP_CABIN_SELECT_TOKEN_DOMAIN: token_domain,
  REACT_APP_CABIN_SELECT_POS_DOMAIN: pos_domain,
  REACT_APP_CABIN_SELECT_API_DOMAIN: api_domain,
  REACT_APP_CABIN_SELECT_APP_LANGUAGE: app_language,
  REACT_APP_CABIN_SELECT_PAYMENT_DOMAIN: payment_domain,
  REACT_APP_CABIN_SELECT_DATE_FORMAT: date_format,
  REACT_APP_CABIN_SELECT_API_LANGUAGE: api_language,
  REACT_APP_CABIN_SELECT_AUTHORIZE_API_LOGIN_ID: authorize_api_login_id,
  REACT_APP_CABIN_SELECT_AUTHORIZE_CLIENT_KEY: authorize_client_key,
  REACT_APP_CABIN_SELECT_TABLE_VIEW: table_view,
  REACT_APP_CABIN_SELECT_API_AGENT: api_agent,
  REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_MINE: authorize_is_mine,
  REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_TEST: authorize_is_test,
  REACT_APP_PAYMENT_API_PATH: payment_api_path,
  REACT_APP_SEARCH_ITEM_IMAGE_SOURCE: search_item_image_source,
  REACT_APP_PRIVACY_POLICY: privacy_policy,
  REACT_APP_TERMS_AND_CONDITIONS: terms_and_conditions,
  REACT_APP_LIABILITY_AND_CANCELLATION_POLICY: liability_and_cancellation,
  REACT_APP_PARTICIPANT_RELEASE_AGREEMENT: participant_release_agreement,
  REACT_APP_SUPPLEMENTAL_TERMS: supplemental_terms,
  REACT_APP_GUEST_TICKET_CONTRACT: guest_ticket_contract,
  REACT_APP_SUBTRACT_GFT: subtract_gft,
  REACT_APP_STRIP_NO_STOP_ITINERARY_ITEMS: strip_no_stop_itinerary_items,
  REACT_APP_SHOW_CANCELLATION_POLICY: show_cancellation_policy,
  REACT_APP_BREADCRUMBS_TEXT: breadcrumbs_text,
  REACT_APP_SHOW_PRICING_BREAKDOWN: show_pricing_breakdown = "true",
  REACT_APP_CHECK_EMAIL_UNIQUENESS: check_email_uniqueness,
  REACT_APP_SHOW_PAGINATION: show_pagination,
  REACT_APP_PAGINATION_TYPE: pagination_type,
  REACT_APP_PAGINATION_COUNT: pagination_count,
  REACT_APP_SKIP_PAYMENT_STEP: skip_payment_step,
  REACT_APP_HEADER_SHOW_CURRENCY_CODE: header_show_currency_code,
  REACT_APP_HEADER_SHOW_LANGUAGE_PICTURE: header_show_language_picture,
  REACT_APP_HEADER_BACKGROUND_COLOR: header_background_color,
  REACT_APP_HEADER_TEXT_COLOR: header_text_color,
  REACT_APP_HEADER_TELEPHONE_NUMBER: header_telephone_number,
  REACT_APP_HEADER_LOGO_PICTURE: header_logo_picture,
  REACT_APP_FOOTER_BACKGROUND_COLOR: footer_background_color,
  REACT_APP_FOOTER_TEXT_COLOR: footer_text_color,
  REACT_APP_FOOTER_EMAIL_ADDRESS: footer_email_address,
  REACT_APP_FOOTER_ADDRESS: footer_address,
  REACT_APP_GFT_ADDITIONAL_TEXT: gft_additional_text,
  REACT_APP_DESTINATION_INPUT_PLACEHOLDER: destination_input_placeholder,
  REACT_APP_BIN_RESTRICTION: bin_restriction,
  REACT_APP_FAVICON: favicon,
  REACT_APP_HEADER_LOGO_HEIGHT: header_logo_height,
  REACT_APP_AIRWALLEX_ENV: airwallex_env,
  REACT_APP_NUMBER_STATEROOM_PAGE_DESCRIPTION: number_stateroom_page_description,
  REACT_APP_CABIN_SELECT_PAYMENT_PROVIDER: payment_provider,
  REACT_APP_UI_VERSION: ui_version,
  REACT_APP_DECK_LEGEND: deck_legend = "",
  REACT_APP_CURRENCY_FORMAT_MODE: currency_format_mode,
} = process.env;
